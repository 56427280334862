import React, { useCallback } from "react";
import PropTypes from "prop-types";
import memoize from "memoizee";
import UsersContext from "./UsersContext";

// api
import { getUser, getUsers } from "../utils/api/Users";
import { getUserReviews } from "../utils/api/Reviews";

export function UsersProvider(props) {
  const getUserDetails = useCallback(async (userId) => {
    try {
      const response = await getUser(userId);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }
      return response;
    } catch (error) {
      return false;
    }
  }, []);

  const fetchReviews = async (createdBy) => {
    try {
      const response = await getUserReviews(createdBy);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }
      return response;
    } catch (error) {
      return false;
    }
  };

  const memoizeGetUserDetails = memoize(getUserDetails, { promise: true });

  const fetchMemberDetails = async (member) => {
    const { memberId } = member;
    const userDetails = await memoizeGetUserDetails(memberId);
    const userImage =
      userDetails && userDetails.UserAttributes
        ? userDetails.UserAttributes.picture
        : null;
    const username =
      userDetails && userDetails.UserAttributes
        ? userDetails.UserAttributes.preferred_username
        : null;
    return {
      ...member,
      userImage,
      username,
    };
  };

  const fetchMembersDetails = async (members) => {
    if (members && members.length) {
      const memberIds = [];
      members.forEach((m) => memberIds.push(m.memberId));
      const response = await getUsers({
        ids: memberIds.join(","),
        limit: "1000",
      });
      const dynamoUsers = response.Users;

      const formatted = members.map((member) => {
        const foundUser = dynamoUsers.find(
          (u) => u.Username === member.memberId,
        );
        const userAttributes = (foundUser && foundUser.Attributes) || {};
        return {
          ...member,
          userImage: userAttributes.picture || "",
          username: userAttributes.preferred_username || "",
        };
      });

      return formatted;
    } else {
      return members;
    }
  };

  return (
    <UsersContext.Provider
      value={{
        getUserDetails,
        fetchMembersDetails,
        fetchMemberDetails,
        fetchReviews,
        memoizeGetUserDetails,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
}

UsersProvider.propTypes = {
  children: PropTypes.node,
};

export default UsersProvider;
