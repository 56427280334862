/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView, trackRecommendedEvent } from "./utils";
import { onLCP, onFID, onCLS } from "web-vitals/attribution";
import ReactGA from "react-ga4";

function sendToGoogleAnalytics({ name, delta, value, id, attribution }) {
  const eventParams = {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.
  };

  switch (name) {
    case "CLS":
      eventParams.debug_target = attribution.largestShiftTarget;
      break;
    case "FID":
      eventParams.debug_target = attribution.eventTarget;
      break;
    case "LCP":
      eventParams.debug_target = attribution.element;
      break;
  }
  trackRecommendedEvent(name, eventParams);
}

const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    trackPageView(
      `${window.location.pathname}${window.location.search}${window.location.hash}`,
    );
    onCLS(sendToGoogleAnalytics);
    onFID(sendToGoogleAnalytics);
    onLCP(sendToGoogleAnalytics);
  }, [location]);
};

export default usePageTracking;
