/* eslint-disable */
import React, { Component } from "react";
import RoleplaysContext from "./RoleplaysContext";
import {
  sendFeedback as sendFeedbackEmailAPI,
  exerciseCompletion as sendExerciseCompletionEmailAPI,
} from "../utils/api/Notifications";

const initialRoleplayState = {};

class RoleplaysProvider extends Component {
  constructor(props) {
    super(props);
    this.state = initialRoleplayState;
  }

  sendFeedbackEmail = async (
    emails,
    username,
    creatername,
    feedback,
    contentname,
    contenttype,
    link,
    state,
  ) => {
    try {
      let recipients = emails;
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          feedback: feedback,
          contentname: contentname,
          contenttype: contenttype,
          username: username,
          creatername: creatername,
          link: link,
          state: state,
        },
      };
      const response = await sendFeedbackEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  sendExerciseCompletionEmail = async (
    emails,
    studentname,
    creatername,
    contentname,
    contenttype,
    link,
  ) => {
    try {
      let recipients = emails;
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          contentname: contentname,
          contenttype: contenttype,
          studentname: studentname,
          creatername: creatername,
          link: link,
        },
      };
      const response = await sendExerciseCompletionEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  render() {
    const { children } = this.props;
    return (
      <RoleplaysContext.Provider
        value={{
          sendFeedbackEmail: this.sendFeedbackEmail,
          sendExerciseCompletionEmail: this.sendExerciseCompletionEmail,
        }}
      >
        {children}
      </RoleplaysContext.Provider>
    );
  }
}

RoleplaysProvider.propTypes = {};

export default RoleplaysProvider;
