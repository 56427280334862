import { apiRequest } from "./index";
import config from "../../config";

const { API } = config;

export async function getCompletionFeedback(completionId, returnvalues) {
  try {
    const response = await apiRequest(
      `${API}/feedback/completions/${encodeURIComponent(completionId)}${returnvalues ? `?${returnvalues}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteFeedback(completionId, feedbackId) {
  try {
    const response = await apiRequest(
      `${API}/feedback/completions/${encodeURIComponent(completionId)}/${feedbackId}`,
      "DELETE",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateFeedback(completionId, feedbackId, body) {
  try {
    const response = await apiRequest(
      `${API}/feedback/completions/${encodeURIComponent(completionId)}/${feedbackId}`,
      "PUT",
      body,
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getMemberFeedback(memberId, returnvalues) {
  try {
    const response = await apiRequest(
      `${API}/feedback/member/${memberId}${returnvalues ? `?${returnvalues}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCourseFeedback(courseId, returnvalues) {
  try {
    const response = await apiRequest(
      `${API}/feedback/courses/${courseId}${returnvalues ? `?${returnvalues}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSectionFeedback(courseId, sectionId, returnvalues) {
  try {
    const response = await apiRequest(
      `${API}/feedback/courses/${courseId}/sections/${sectionId}${returnvalues ? `?${returnvalues}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getTopicFeedback(
  courseId,
  sectionId,
  topicId,
  returnvalues,
) {
  try {
    const response = await apiRequest(
      `${API}/feedback/courses/${courseId}/sections/${sectionId}/topics/${topicId}${returnvalues ? `?${returnvalues}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCourseSectionTopicFeedbacks(
  courseId,
  sectionId,
  topicId,
  memberId,
  returnvalues,
) {
  try {
    const response = await apiRequest(
      `${API}/feedback/courses/${courseId}/sections/${sectionId}/topics/${topicId}/member/${memberId}${returnvalues ? `?${returnvalues}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCourseSectionTopicFeedback(
  courseId,
  sectionId,
  topicId,
  memberId,
  createdAt,
) {
  try {
    const response = await apiRequest(
      `${API}/feedback/courses/${courseId}/sections/${sectionId}/topics/${topicId}/member/${memberId}/${createdAt}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getBotFeedbacks(botId, memberId) {
  try {
    const response = await apiRequest(
      `${API}/feedback/bots/${botId}${memberId ? `/member/${memberId}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getBotFeedback(botId, memberId, createdAt) {
  try {
    const response = await apiRequest(
      `${API}/feedback/bots/${botId}/member/${memberId}/${createdAt}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getRoleplayFeedbacks(roleplayId, memberId) {
  try {
    const response = await apiRequest(
      `${API}/feedback/roleplays/${roleplayId}${memberId ? `/member/${memberId}` : ""}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getRoleplayFeedback(roleplayId, memberId, createdAt) {
  try {
    const response = await apiRequest(
      `${API}/feedback/roleplays/${roleplayId}/member/${memberId}/${createdAt}`,
      "GET",
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function createFeedback(body) {
  try {
    const response = await apiRequest(`${API}/feedback`, "POST", body);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateCourseSectionTopicFeedback(
  courseId,
  sectionId,
  topicId,
  memberId,
  createdAt,
  body,
) {
  try {
    const response = await apiRequest(
      `${API}/feedback/courses/${courseId}/sections/${sectionId}/topics/${topicId}/member/${memberId}/${createdAt}`,
      "PUT",
      body,
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteCourseSectionTopicFeedback(
  courseId,
  sectionId,
  topicId,
  memberId,
  createdAt,
) {
  try {
    const response = await apiRequest(
      `${API}/feedback/courses/${courseId}/sections/${sectionId}/topics/${topicId}/member/${memberId}/${createdAt}`,
      "DELETE",
    );
    return response;
  } catch (error) {
    throw error;
  }
}
