import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ColorModeContext } from "./ColorModeContext";
import getTheme from "./getTheme";

const allowedModes = ["light", "dark"];

export default function AppThemeProvider(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("nualang-theme", newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = useMemo(() => createTheme(getTheme(mode, true)), [mode]);

  useEffect(() => {
    const themePreference = localStorage.getItem("nualang-theme");
    if (themePreference && allowedModes.includes(themePreference)) {
      if (themePreference !== mode) {
        setMode(themePreference);
      }
    } else if (prefersDarkMode && mode !== "dark") {
      setMode("dark");
    }
  }, [prefersDarkMode, mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

AppThemeProvider.propTypes = {
  children: PropTypes.node,
};
