import { searchStringToObj, objToSearchString } from "../utils/index";

const onboardingBasePath = `/welcome`;

const useUserOnboarding = ({ authenticated, authData }) => {
  const params = searchStringToObj(window.location.search);

  const generatePath = () => {
    try {
      let qsParams;
      if (params.plan === "learn") {
        if (params.learnlang) {
          qsParams = objToSearchString({
            plan: "learn",
            learnlang: params.learnlang,
            redirect: window.location.href,
          });
        } else {
          qsParams = objToSearchString({
            plan: "learn",
            redirect: window.location.href,
          });
        }
      } else if (params.plan === "teacher_pro") {
        if (params.learnLang) {
          qsParams = objToSearchString({
            plan: "teacher_pro",
            learnlang: params.learnlang,
            redirect: window.location.href,
          });
        } else {
          qsParams = objToSearchString({
            plan: "teacher_pro",
            redirect: window.location.href,
          });
        }
      } else if (params.plan === "teach") {
        if (params.learnLang) {
          qsParams = objToSearchString({
            plan: "teach",
            learnlang: params.learnlang,
            redirect: window.location.href,
          });
        } else {
          qsParams = objToSearchString({
            plan: "teach",
            redirect: window.location.href,
          });
        }
      } else {
        qsParams = objToSearchString({
          redirect: window.location.href,
        });
      }
      return `${onboardingBasePath}?${qsParams}`;
    } catch (error) {
      return "";
    }
  };

  const isOnboardingRequired =
    authenticated &&
    authData &&
    authData.attributes &&
    !authData.attributes["custom:role"] &&
    !window.location.pathname.includes("welcome");

  const onboardingPath = isOnboardingRequired ? generatePath() : "";

  return {
    isOnboardingRequired,
    onboardingPath,
  };
};

export default useUserOnboarding;
