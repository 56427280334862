// import React from 'react';
// import PropTypes from 'prop-types';
// import makeStyles from '@mui/styles/makeStyles';
// import {
//   AppBar, Toolbar, Typography, Link, Button,
// } from '@mui/material';
// import Slide from '@mui/material/Slide';

// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     top: 'auto',
//     bottom: 0,
//     padding: theme.spacing(),
//     zIndex: 1200,
//   },
//   toolbar: {
//     display: 'flex',
//     justifyContent: 'space-evenly',
//     alignItems: 'center',
//     flexDirection: 'row',
//     paddingTop: theme.spacing(),
//     paddingBottom: theme.spacing(),
//   },
//   heading: {
//     fontWeight: 700,
//   },
//   description: {
//     paddingBottom: theme.spacing(1.5),
//   },
//   buttons: {
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column',
//     },
//     display: 'flex',
//   },
//   notNow: {
//     color: 'white',
//     marginRight: theme.spacing(),
//   },
//   payNow: {
//     [theme.breakpoints.down('sm')]: {
//       marginTop: theme.spacing(),
//     },
//     textDecoration: 'none !important',
//     marginRight: theme.spacing(),
//   },
// }));

// export default function Offer({
//   isOpen, invoiceUrl, payNowUrl, payNow, notNow, heading, description,
// }) {
//   const classes = useStyles();
//   return (
//     isOpen
//     && <React.Fragment>
//       <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
//         <AppBar position="fixed" className={classes.appBar}>
//           <Toolbar className={classes.toolbar}>
//             <div>
//               <Typography
//                 variant="h6"
//                 color="inherit"
//                 className={classes.heading}
//                 gutterBottom
//               >
//                 {heading}
//               </Typography>
//               <Typography variant="body1" color="inherit" className={classes.description}>
//                 {description}
//               </Typography>
//               <div className={classes.buttons}>
//                 <Button
//                   onClick={notNow}
//                   className={classes.notNow}
//                   size="small"
//                 >
//                   Not now
//                 </Button>
//                 <Button
//                   onClick={payNow}
//                   variant="contained"
//                   component={Link}
//                   href={payNowUrl}
//                   className={classes.payNow}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Pay Now
//                 </Button>
//                 <Button
//                   onClick={payNow}
//                   variant="contained"
//                   component={Link}
//                   href={invoiceUrl}
//                   className={classes.payNow}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Have Us Invoice You
//                 </Button>
//               </div>
//             </div>
//           </Toolbar>
//         </AppBar>
//       </Slide>
//     </React.Fragment>
//   );
// }

// Offer.propTypes = {
//   children: PropTypes.string,
//   payNowUrl: PropTypes.string,
//   invoiceUrl: PropTypes.string,
//   payNow: PropTypes.func,
//   notNow: PropTypes.func,
//   isOpen: PropTypes.bool,
//   heading: PropTypes.heading,
//   description: PropTypes.description,
// };
