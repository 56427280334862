/* eslint-disable */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import FeedbackContext from "./FeedbackContext";
import withAppContext from "../hoc/withAppContext";
import withUsersContext from "../hoc/withUsersContext";
import {
  createFeedback as createFeedbackAPI,
  updateFeedback as updateFeedbackAPI,
  deleteFeedback as deleteFeedbackAPI,
  getCompletionFeedback,
} from "../utils/api/Feedback";
import { DataType, invalidateCache } from "../utils/api/invalidation";

export function FeedbackProvider(props) {
  const [completionFeedback, setCompletionFeedback] = useState({});

  const createFeedback = async (body) => {
    const {
      appContext: { openSnackbar, startLoading, stopLoading },
    } = props;

    try {
      startLoading();
      const response = await createFeedbackAPI(body);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }

      invalidateCache(DataType.FEEDBACK, {
        completionId: response.Item.completionId,
      });
      setCompletionFeedback(response.Item);
      stopLoading();
    } catch (error) {
      console.error(error);
      openSnackbar("There was a problem adding feedback", "error");
      stopLoading();
    }
  };

  const fetchCompletionFeedback = useCallback(async (completionId) => {
    const {
      appContext: { openSnackbar, startLoading, stopLoading },
    } = props;
    try {
      startLoading();
      const response = await getCompletionFeedback(completionId);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }
      setCompletionFeedback(response.Items[0]);
      stopLoading();
    } catch (error) {
      console.error(error);
      openSnackbar("There was a problem loading activity", "error");
      stopLoading();
    }
  }, []);

  const deleteFeedback = async (completionId, feedbackId) => {
    const {
      appContext: { openSnackbar, startLoading, stopLoading },
    } = props;
    try {
      startLoading();
      const response = await deleteFeedbackAPI(completionId, feedbackId);

      invalidateCache(DataType.FEEDBACK, {
        completionId: response.CourseSectionTopicMemberId,
      });

      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }
      setCompletionFeedback(null);
      openSnackbar("Feedback deleted", "success");
      stopLoading();
    } catch (error) {
      console.error(error);
      openSnackbar("There was a problem deleting feedback", "error");
      stopLoading();
    }
  };

  const updateFeedback = async (completionId, feedbackId, body) => {
    const {
      appContext: { openSnackbar, startLoading, stopLoading },
    } = props;

    try {
      startLoading();
      const response = await updateFeedbackAPI(completionId, feedbackId, body);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }

      invalidateCache(DataType.FEEDBACK, {
        completionId: response.CourseSectionTopicMemberId,
      });
      setCompletionFeedback({ ...completionFeedback, ...response.Attributes });
      openSnackbar("Feedback updated", "success");
      stopLoading();
    } catch (error) {
      console.error(error);
      openSnackbar("There was a problem updating feedback", "error");
      stopLoading();
    }
  };

  return (
    <FeedbackContext.Provider
      value={{
        completionFeedback,
        createFeedback,
        deleteFeedback,
        updateFeedback,
        fetchCompletionFeedback,
      }}
    >
      {props.children}
    </FeedbackContext.Provider>
  );
}

FeedbackProvider.propTypes = {
  usersContext: PropTypes.object,
  appContext: PropTypes.object,
};

export default withAppContext(withUsersContext(FeedbackProvider));
