import React from "react";

import { AppContextConsumer } from "../context/AppContext";

export default function withAppContext(Component) {
  return function WrapperComponent(props) {
    return (
      <AppContextConsumer>
        {(state) => <Component {...props} appContext={state} />}
      </AppContextConsumer>
    );
  };
}
