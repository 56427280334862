import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

function ScrollToTop({ children, disableLiveChat }) {
  const componentMounted = useRef();
  const prevPath = useRef();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (!componentMounted.current) {
      componentMounted.current = true;
    } else if (prevPath.current !== pathname) {
      window.scrollTo(0, 0);
      prevPath.current = pathname;
      /*
        widget.refresh

        Refresh and re-render the chat widget's information, given the current page URL.

        If you house the chat widget on a single-page application,
        this method can be useful for refreshing the widget on route changes.
        This allows you to specify different chatflows on different page routes.
        If widget.refresh is called on a route where there is no chatflow,
        and the user isn't engaged in a conversation, the widget will be removed.

        Note: widget.refresh is throttled to one call per second.
      */
      if (window.HubSpotConversations) {
        // The api is ready already
        if (disableLiveChat) {
          window.HubSpotConversations.widget.remove();
        } else {
          window.HubSpotConversations.widget.refresh();
        }
      } else {
        window.hsConversationsOnReady = [
          () => {
            // Now the api is ready
            if (disableLiveChat) {
              window.HubSpotConversations.widget.remove();
            } else {
              window.HubSpotConversations.widget.refresh();
            }
          },
        ];
      }
    }
  }, [location.pathname, disableLiveChat, location]);

  return children;
}

ScrollToTop.propTypes = {
  children: PropTypes.node,
};

export default ScrollToTop;
