// https://ui.docs.amplify.aws/
import React from "react";
import PropTypes from "prop-types";
import {
  Authenticator,
  Flex,
  Image,
  useTheme,
  Heading,
  useAuthenticator,
  Text,
  Link,
  Button,
} from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import logo from "../../img/logo/nualang-logo-primary.svg";
import waysideIcon from "../../img/logo/wayside-icon.svg";
import config from "../../config";

export function Header() {
  const { tokens } = useTheme();
  return (
    <Flex justifyContent="center">
      <Link
        href={`${config.SITE_URL}/`}
        isExternal={true}
        style={{
          display: "contents",
        }}
      >
        <Image
          alt="logo"
          src={logo}
          padding={tokens.space.medium}
          style={{
            maxWidth: 180,
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        />
      </Link>
    </Flex>
  );
}

export function WaysideButton({ text }) {
  const { tokens } = useTheme();
  return (
    <>
      <Heading
        level={3}
        padding={`${tokens.space.medium} ${tokens.space.xl} 0 ${tokens.space.xl}`}
      >
        <Link href={config.WAYSIDE_LEARNING_SITE_URL}>
          <Button
            style={{
              width: "100%",
              bottom: "-16px",
              position: "relative",
            }}
            gap="0.8rem"
          >
            <img
              src={waysideIcon}
              style={{
                height: "25px",
              }}
            />
            <Text fontWeight="normal" as="span">
              {text}
            </Text>
          </Button>
        </Link>
      </Heading>
    </>
  );
}

export function SignInHeader() {
  if (!window?.location?.pathname?.includes("Wayside")) {
    return null;
  }
  return <WaysideButton text="Sign In with Learning Site" />;
}

export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();
  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Link onClick={toResetPassword}>Reset your password</Link>
    </Flex>
  );
}

export function SignUpHeader() {
  if (!window?.location?.pathname?.includes("Wayside")) {
    return null;
  }
  return <WaysideButton text="Sign Up with Learning Site" />;
}

export function Footer() {
  const { tokens } = useTheme();
  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>&copy; All Rights Reserved</Text>
    </Flex>
  );
}

function TermsCheckbox(props) {
  const {
    name,
    value,
    fieldId,
    checked,
    disabled,
    LabelComponent,
    errorMessage,
  } = props;

  const [checkedState, setCheckedState] = React.useState(checked);

  const onClick = () => {
    setCheckedState(!checkedState);
  };

  return (
    <span className="checkbox">
      <label
        style={{
          cursor: "pointer",
          marginBottom: "8px",
        }}
      >
        <input
          onClick={onClick}
          type="checkbox"
          name={name}
          value={value}
          id={fieldId}
          checked={checked}
          disabled={disabled}
          style={{
            marginRight: "0.5rem",
          }}
        />
        {LabelComponent}
      </label>
      <Text
        variation="error"
        style={{
          marginTop: "1rem",
        }}
      >
        {errorMessage}
      </Text>
    </span>
  );
}

TermsCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  fieldId: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  LabelComponent: PropTypes.node,
};

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    //   Footer: SignInFooter
  },
  SignUp: {
    Header: SignUpHeader,
    FormFields() {
      const { validationErrors } = useAuthenticator();

      const { t } = useTranslation();

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />
          {/* Append & require Terms & Conditions field to sign up  */}
          <TermsCheckbox
            errorMessage={validationErrors.acceptTerms}
            hasError={!!validationErrors.acceptTerms}
            name="acceptTerms"
            value="yes"
            LabelComponent={
              <>
                {/* eslint-disable-next-line max-len */}
                {t("agree_to_terms_and_conditions_1")}{" "}
                <a
                  href={`${config.SITE_URL}/terms`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("nualang_terms")}
                </a>
                {t("agree_to_terms_and_conditions_2")}
                <a
                  href={`${config.SITE_URL}/privacy`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("privacy_policy")}
                </a>
                .
              </>
            }
          />
        </>
      );
    },
    // Footer: () => JSX.Element;
  },
  // Footer,
};

export default components;
