import { green, red } from "@mui/material/colors";
import primary from "./colors/primary";
import secondary from "./colors/secondary";
import getComponents from "./getComponents";

const getTheme = (mode, isApp = false) => {
  const palette = {
    mode,
    primary: {
      ...(mode === "light"
        ? {
            light: primary[500],
            main: primary[700],
            dark: primary[900],
          }
        : {
            light: primary[100],
            main: primary[200],
            dark: primary[400],
          }),
    },
    secondary: {
      ...(mode === "light"
        ? {
            light: secondary[400],
            main: secondary[600],
            dark: secondary[800],
          }
        : {
            light: secondary[100],
            main: secondary[200],
            dark: secondary[300],
          }),
    },
    success: {
      ...(mode === "light"
        ? {
            light: green[300],
            main: green[600],
            dark: green[800],
          }
        : {
            light: green[100],
            main: green[200],
            dark: green[300],
          }),
    },
    error: {
      ...(mode === "light"
        ? {
            light: red[400],
            main: red[700],
            dark: red[900],
          }
        : {
            light: red[100],
            main: red[200],
            dark: red[200],
          }),
    },
    text: {
      ...(isApp
        ? {}
        : {
            primary: "#212353",
          }),
    },
    background: {
      ...(isApp
        ? {
            paper: mode === "dark" ? "#303030" : "#fff",
            default: mode === "dark" ? "#303030" : "#fafafa",
          }
        : {
            paper: "#fff",
            default: "#fff",
          }),
    },
  };

  return {
    components: getComponents(mode, palette),
    palette,
    typography: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: isApp ? 14 : 16,
      textTransform: "none",
    },
  };
};

export default getTheme;
