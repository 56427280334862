import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as router from "react-router-dom";
import ModalFactory from "react-modal-promise";
import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";
import Breadcrumbs from "@nualang/nualang-ui-components/dist/Navigation/Breadcrumbs";
import UIfx from "uifx";
import { users as userQuerys } from "@nualang/nualang-ui-components/dist/hooks/querys";
import correctAudio from "./utils/audio/correct.wav";
import incorrectAudio from "./utils/audio/incorrect.wav";
import tadaAudio from "./utils/audio/tada.mp3";
import { getUser } from "./utils/api/Users";
import Layout from "./layouts/App";
import routes from "./routes";
import { noAuthPaths } from "./routes";
import withAuth from "./hoc/withAuth";
import Loading from "./components/Misc/Loading";
import withRoot from "./hoc/withRoot";
import usePageTracking from "./usePageTracking";
import DefaultError from "./views/DefaultError/DefaultError";
import useLiveReload from "./hooks/useLiveReload";
import useUserOnboarding from "./hooks/useUserOnboarding";
import { trackRecommendedEvent } from "./utils";

const config = [{ trackingId: process.env.REACT_APP_GA4_TRACKING_ID }];

ReactGA.initialize(config);

const { Route, Routes, Navigate, useNavigate, useLocation } = router;

const Content = styled("div")(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.text.primary,
}));

function AppRoute(props) {
  usePageTracking();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    route,
    authData,
    themeContext,
    correctSound,
    incorrectSound,
    tadaSound,
    subscriptionPlan,
    authenticated,
    noAuth,
    handleSignIn,
    handleCloseSignIn,
    isAuthDialogOpen,
    setIsAuthDialogOpen,
  } = props;

  const { isOnboardingRequired, onboardingPath } = useUserOnboarding({
    authenticated,
    authData,
  });

  const isAuthRequired = authenticated === false && noAuth === false;

  useLiveReload();

  useEffect(() => {
    if (isAuthRequired && !isAuthDialogOpen) {
      handleSignIn();
    }
    if (
      isAuthDialogOpen === true &&
      noAuthPaths.some((path) => location.pathname.includes(path))
    ) {
      // Temp disable so query param doesn't get removed
      // window.history.pushState(null, null, location.pathname);
    }
    window.addEventListener("popstate", function () {
      if (!authenticated) {
        handleCloseSignIn();
      }
    });
  }, [
    isAuthDialogOpen,
    handleSignIn,
    handleCloseSignIn,
    isAuthRequired,
    location,
  ]);

  useEffect(() => {
    if (route.title && route.title !== document.title) {
      document.title = route.title;
    }
  }, [route.title]);

  if (isOnboardingRequired && onboardingPath && route.path !== `/welcome`) {
    return <Navigate to={onboardingPath} />;
  }

  return (
    <ErrorBoundary
      onError={(error = "No error log found", errorInfo = {}) => {
        try {
          const route = window.location.pathname || "No route found";
          const date = new Date().toLocaleDateString();
          trackRecommendedEvent("app_error", {
            A1_errorInfoSummary: `Error: ${error} and Route: ${route}`,
            A2_route: route,
            A3_date: date,
            A4_componentStackTrace:
              errorInfo.componentStack ?? "No component stack trace found",
          });
          // doesn't have to navigate to display fallbackcomponent
          // navigate(`/error${route ? `${route}` : ""}`);
        } catch (err) {
          console.error(err);
        }
      }}
      FallbackComponent={DefaultError}
    >
      {isAuthRequired ? null : (
        <route.component
          authData={authData}
          themeContext={themeContext}
          name={route.name}
          handleViewExerciseAttempt={(event) => {
            const {
              exerciseMemberId,
              createdBy,
              courseId,
              sectionId,
              topicId,
              exercise,
              createdAt,
              botId,
              roleplayId,
              classroomId,
            } = event;
            if (classroomId) {
              navigate(
                `/classrooms/${classroomId}/activity/courses/${courseId}/${sectionId}/${topicId}/${exercise}/${createdBy}/${createdAt}`,
              );
            } else if (
              exerciseMemberId.split("|").length > 2 // activity that's part of course includes courseId,topicId,sectionId
            ) {
              navigate(
                `/courses/${courseId}/${sectionId}/${topicId}/activity/${exercise}/${createdBy}/${createdAt}`,
              );
            } else if (!botId.includes("none-")) {
              navigate(`/bots/${botId}/activity/${createdBy}/${createdAt}`);
            } else if (!roleplayId.includes("none-")) {
              navigate(
                `/roleplays/${roleplayId}/activity/${createdBy}/${createdAt}`,
              );
            }
          }}
          correctSound={correctSound}
          incorrectSound={incorrectSound}
          tadaSound={tadaSound}
          subscriptionPlan={subscriptionPlan}
          navigate={navigate}
          handleSignIn={handleSignIn}
          location={location}
          setIsAuthDialogOpen={setIsAuthDialogOpen}
          isAuthDialogOpen={isAuthDialogOpen}
          authenticated={authenticated}
        />
      )}
    </ErrorBoundary>
  );
}

AppRoute.propTypes = {
  route: PropTypes.object,
  authData: PropTypes.object,
  themeContext: PropTypes.object,
  location: PropTypes.object,
  subscriptionPlan: PropTypes.string,
  correctSound: PropTypes.object,
  incorrectSound: PropTypes.object,
  tadaSound: PropTypes.object,
};

let correctSound, incorrectSound, tadaSound;

function AppIndexPage(props) {
  const {
    authState,
    authData,
    themeContext,
    location,
    isAuthDialogOpen,
    setIsAuthDialogOpen,
    authenticated,
  } = props;

  const [soundEffects, setSoundEffects] = useState({
    correctSound: correctSound || null,
    incorrectSound: incorrectSound || null,
    tadaSound: tadaSound || null,
  });

  const userQuery = userQuerys.useUser(
    async (userId) => {
      const response = await getUser(userId);
      return response;
    },
    {
      userId: authData && authData.username,
    },
    {
      enabled: !!(authData && authData.username),
    },
  );
  const user = userQuery.isSuccess && userQuery.data ? userQuery.data : {};
  const subscriptionPlan = user && user.plan ? user.plan : "basic";
  const isBasicPlan =
    user &&
    user.plan &&
    authData &&
    authData.attributes &&
    authData.attributes["custom:role"] === "teach"
      ? user.plan !== "teach"
      : null;

  useEffect(() => {
    if (
      !soundEffects.correctSound ||
      !soundEffects.incorrectSound ||
      !soundEffects.tadaSound
    ) {
      if (!correctSound) {
        correctSound = new UIfx(correctAudio, { volume: 0.5 });
      }
      if (!incorrectSound) {
        incorrectSound = new UIfx(incorrectAudio, { volume: 0.4 });
      }
      if (!tadaSound) {
        tadaSound = new UIfx(tadaAudio, { volume: 0.3 });
      }
      setSoundEffects({
        correctSound: correctSound,
        incorrectSound: incorrectSound,
        tadaSound: tadaSound,
      });
    }
  }, []);

  return (
    <>
      <Layout
        location={location}
        authState={authState}
        authData={authData}
        themeContext={themeContext}
        subscriptionPlan={subscriptionPlan}
        isBasicPlan={isBasicPlan}
        authenticated={authenticated}
      >
        <Breadcrumbs appRoutes={routes} router={router} />
        <Content id="maincontent">
          <Suspense fallback={<Loading />}>
            <Routes>
              {routes.map((route, idx) =>
                route.component ? (
                  <Route
                    key={`appindexpage_${idx}`}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={
                      <AppRoute
                        route={route}
                        authData={authData}
                        themeContext={themeContext}
                        correctSound={soundEffects.correctSound}
                        incorrectSound={soundEffects.incorrectSound}
                        tadaSound={soundEffects.tadaSound}
                        subscriptionPlan={subscriptionPlan}
                        authenticated={authenticated}
                        handleSignIn={() => setIsAuthDialogOpen(true)}
                        handleCloseSignIn={() => setIsAuthDialogOpen(false)}
                        isAuthDialogOpen={isAuthDialogOpen}
                        setIsAuthDialogOpen={setIsAuthDialogOpen}
                        noAuth={route.noAuth === true ? true : false}
                      />
                    }
                  />
                ) : null,
              )}
              <Route
                path="/dashboard"
                element={<Navigate replace to="/explore" />}
              />
              <Route path="/" element={<Navigate replace to="/classrooms" />} />
            </Routes>
          </Suspense>
        </Content>
        <ModalFactory />
      </Layout>
    </>
  );
}

AppIndexPage.propTypes = {
  authState: PropTypes.string,
  authData: PropTypes.object,
  themeContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({
    site: PropTypes.object,
    termsVersion: PropTypes.string,
  }),
};

export default withRoot(withAuth(AppIndexPage));
