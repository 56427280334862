import React from "react";

import { UsersConsumer } from "../context/UsersContext";

export default function withUsersContext(Component) {
  return function WrapperComponent(props) {
    return (
      <UsersConsumer>
        {(state) => <Component {...props} usersContext={state} />}
      </UsersConsumer>
    );
  };
}
