/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import ClassroomsContext from "./ClassroomsContext";
import withAppContext from "../hoc/withAppContext";

// api
import {
  createClassroomReview,
  updateClassroomReview,
  deleteClassroomReview,
} from "../utils/api/ClassroomReviews";
import { DataType, invalidateCache } from "../utils/api/invalidation";
import { sendCollaboratorEmail as sendCollaboratorEmailAPI } from "../utils/api/Notifications";

import config from "../config";

export function ClassroomsProvider(props) {
  const [hasMoreClassrooms, setHasMoreClassrooms] = useState(true);
  const [classrooms, setClassrooms] = useState([]);
  const [classroom, setClassroom] = useState({
    classroomName: null,
    description: null,
    learnLang: null,
    forLang: null,
    members: [],
    courses: [],
    reviews: [],
  });
  const [updateReview, setUpdateReview] = useState({
    reviewTitle: "",
    reviewContent: "",
    starRating: 0,
  });
  const [hasReviewed, setHasReviewed] = useState(false);
  const [filters, setFilters] = useState({
    learnLang: "",
    forLang: "",
    orderBy: "",
    classroomIds: "",
    limit: "12",
    visibility: "public",
    returnvalues: "",
    lastEvaluatedKey: "",
    verbose: false,
  });
  const [joinClassroomDialogOpen, setJoinCourseDialogOpen] = useState(false);
  const [newClassroom, setNewClassroom] = useState("");
  const memberLimit = 200;

  const resetClassroomsState = () => {
    setLearnCourses([]);
    setTeachCourses([]);
  };

  const resetSearchClassroomsState = () => {
    setClassrooms([]);
    setHasMoreClassrooms(true);
    setFilters({ ...filters, lastEvaluatedKey: "" });
  };

  const createReview = async (classroomId, review) => {
    const {
      appContext: {
        openSnackbar,
        startLoading,
        stopLoading,
        user: { username },
      },
    } = props;
    const { reviewTitle, reviewContent, starRating } = review;
    const type = "classroom";

    if (!starRating || !reviewContent) {
      openSnackbar("missing_details", "error");
      return;
    }

    if (hasReviewed) {
      try {
        startLoading();
        const body = {
          reviewTitle: reviewTitle,
          reviewContent: reviewContent,
          starRating: starRating,
        };
        const response = await updateClassroomReview(
          classroomId,
          username,
          body,
        );

        invalidateCache(DataType.REVIEW_CLASSROOM, {
          classroomId,
        });

        const newClassroomReviews = classroom.reviews;
        const reviewIndex = newClassroomReviews.findIndex(
          (r) => r.createdBy === username,
        );
        if (reviewIndex > -1) {
          newClassroomReviews[reviewIndex] = {
            ...newClassroomReviews[reviewIndex],
            ...body,
          };
        }
        setClassroom({ ...classroom, reviews: newClassroomReviews });
        setHasReviewed(true);
        setUpdateReview({ ...updateReview, ...body });
        openSnackbar("Review was successfully updated");
        stopLoading();
        return response;
      } catch (error) {
        console.error(error);
        openSnackbar("There was an error updating the review", "error");
        stopLoading();
      }
    } else {
      try {
        startLoading();
        const body = {
          itemID: classroomId,
          type: type,
          reviewTitle: reviewTitle,
          reviewContent: reviewContent,
          starRating: starRating,
        };
        const response = await createClassroomReview(body);

        invalidateCache(DataType.REVIEW_CLASSROOM, {
          classroomId: response.Item.itemID,
        });

        setClassroom({
          ...classroom,
          reviews: [...classroom.reviews, response.Item],
        });
        setHasReviewed(true);
        setUpdateReview({
          ...updateReview,
          reviewTitle: response.Item.reviewTitle,
          reviewContent: response.Item.reviewContent,
          starRating: response.Item.starRating,
        });
        openSnackbar("Review was successfully created");
        stopLoading();
        return response;
      } catch (error) {
        console.error(error);
        openSnackbar("There was an error creating the review", "error");
        stopLoading();
      }
    }
  };

  const deleteReview = async (classroomId) => {
    const {
      appContext: {
        openSnackbar,
        startLoading,
        stopLoading,
        user: { username },
      },
    } = props;
    try {
      startLoading();
      const response = await deleteClassroomReview(classroomId, username);

      invalidateCache(DataType.REVIEW_CLASSROOM, {
        classroomId,
      });

      setClassroom({
        ...classroom,
        reviews: classroom.reviews.filter((r) => r.createdBy !== username),
      });
      setHasReviewed(false);
      setUpdateReview({ reviewTitle: "", reviewContent: "", starRating: 0 });
      openSnackbar("Review was successfully deleted");
      stopLoading();
      return response;
    } catch (error) {
      console.error(error);
      openSnackbar("There was an error deleting the review", "error");
      stopLoading();
    }
  };

  const joinPrivateClassroom = () => {
    setJoinCourseDialogOpen(true);
  };

  const handleClosePrivateClassroomDialog = () => {
    setJoinCourseDialogOpen(false);
  };

  const sendCollaboratorEmail = async (
    emails,
    contentName,
    contentType,
    classroomCollaborators,
    id,
    creatorName,
    learnlang,
  ) => {
    try {
      let recipients = emails;
      recipients = emails.filter(
        (email) => !classroomCollaborators.includes(email),
      );
      if (recipients.length === 0) {
        return false;
      }
      const email = {
        recipients,
        params: {
          contentName: contentName,
          contentType: contentType,
          creatorName: creatorName,
          inviteLink: `${config.APP_URL}/classrooms/${id}?learnlang=${learnlang}&plan=teach&newCollaborator=true`,
        },
      };
      const response = await sendCollaboratorEmailAPI(email);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <ClassroomsContext.Provider
      value={{
        newClassroom,
        setNewClassroom,
        joinClassroomDialogOpen,
        joinPrivateClassroom,
        handleClosePrivateClassroomDialog,
        hasMoreClassrooms,
        classrooms,
        classroom,
        filters,
        updateReview,
        hasReviewed,
        resetClassroomsState,
        resetSearchClassroomsState,
        createReview,
        deleteReview,
        sendCollaboratorEmail,
        memberLimit,
      }}
    >
      {props.children}
    </ClassroomsContext.Provider>
  );
}

ClassroomsProvider.propTypes = {
  appContext: PropTypes.object,
};

export default withAppContext(ClassroomsProvider);
