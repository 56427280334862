import { createTheme } from "@mui/material/styles";
import StarBorder from "@mui/icons-material/StarBorder";

const getComponents = (mode, palette) => {
  const defaultTheme = createTheme({
    palette,
  });

  const focusIndicator = {
    outline: `2px ${defaultTheme.palette.background.default} solid`,
    outlineOffset: 0,
    boxShadow: `0 0 0 4px ${mode === "light" ? "#0b57d0" : "white"}`,
  };

  const disableRipple = true;

  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple,
      },
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": focusIndicator,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: disableRipple,
      },
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": focusIndicator,
        },
        contained: {
          borderRadius: "25px",
          paddingLeft: "20px",
          paddingRight: "20px",
          fontWeight: 600,
        },
        outlined: {
          borderRadius: "25px",
          paddingLeft: "20px",
          paddingRight: "20px",
          fontWeight: 600,
        },
        text: {
          borderRadius: "25px",
          paddingLeft: "8px",
          paddingRight: "8px",
          fontWeight: 600,
          border: "1px solid transparent",
          "&:hover": {
            border: `1px solid currentColor`,
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": {
            border: `2px ${mode === "light" ? "#0b57d0" : "white"} solid`,
          },
          "&.Mui-focusVisible .MuiCardActionArea-focusHighlight": {
            opacity: 0,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up("sm")]: {
            minWidth: "160px",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: disableRipple,
      },
      styleOverrides: {
        root: {
          border: "1px solid transparent",
          "&.Mui-focusVisible": {
            backgroundColor:
              mode === "light"
                ? "rgba(100, 100, 100, 0.10)"
                : "rgba(255, 255, 255, 0.12)",
            ...focusIndicator,
          },
          "&:hover": {
            border: `1px solid currentColor`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": focusIndicator,
        },
      },
    },
    MuiRating: {
      defaultProps: {
        emptyIcon: (
          <StarBorder
            fontSize="inherit"
            sx={{ color: mode === "light" ? "#757575" : "white" }}
          />
        ),
      },
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": focusIndicator,
          color: defaultTheme.palette.primary.main,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": focusIndicator,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.Mui-focusVisible": focusIndicator,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          overflow: "visible",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: (props) => ({
          "& .MuiFormLabel-root": {
            color: props.disabled
              ? "#BDBDBD"
              : defaultTheme.palette.mode === "dark"
                ? "white"
                : "black",
          },
          "& label.Mui-focused": {
            color: defaultTheme.palette.primary.main,
          },
          "&:hover label": {
            color: defaultTheme.palette.primary.main,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#8D8D8D",
            },
            "&:hover fieldset": {
              borderColor: props.disabled
                ? "#BDBDBD"
                : defaultTheme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: (props) => ({
          "& .MuiFormLabel-root": {
            color: props.disabled
              ? "#BDBDBD"
              : defaultTheme.palette.mode === "dark"
                ? "white"
                : "black",
          },
          "& label.Mui-focused": {
            color: defaultTheme.palette.primary.main,
          },
          "&:hover label": {
            color: defaultTheme.palette.primary.main,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#8D8D8D",
            },
            "&:hover fieldset": {
              borderColor: props.disabled
                ? "#BDBDBD"
                : defaultTheme.palette.primary.main,
            },
          },
        }),
      },
    },
  };
};

export default getComponents;
