import { useEffect } from "react";

const useLiveReload = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      new EventSource("/esbuild").addEventListener("change", () => {
        window.location.reload();
      });
    }
  }, []);
};
export default useLiveReload;
